import React from 'react';
import PropTypes from 'prop-types';

import Helmet from '../project/Helmet';
import LogInForm from './LogInForm';
import withSessionHOC from '../sessionProvider/withSessionHOC';
import {Trans, t} from '@lingui/macro';
import { SESSION_ATTRIBUTES } from '../sessionProvider/SessionProvider';

/**
 * @fero
 */

class LoginPage extends React.PureComponent {
    static propTypes = {
        [SESSION_ATTRIBUTES.RELOAD_RIGHTS]: PropTypes.func.isRequired,
    };

    onSubmitFinished = () => {
        const {[SESSION_ATTRIBUTES.RELOAD_RIGHTS]: reloadRights} = this.props;
        reloadRights();
    };

    render() {
        return <React.Fragment>
            <Helmet
                title={t`Prihlásenie`}
            />
            <div className="p-5 full-size-width d-flex flex-column align-items-center">
                <h1 className="pt-5 px-5"><Trans>Prosím prihláste sa</Trans></h1>
                <span className="px-5 pb-5"><Trans>Boli ste presmerovaný zo stránky na ktorú nemáte oprávnenie. Pravdepodobne je potrebné prihlásenie.</Trans></span>
                <LogInForm values={{}} onClose={() => {}} onSubmitFinish={this.onSubmitFinished}/>
            </div>
        </React.Fragment>;
    }

}

export default withSessionHOC([SESSION_ATTRIBUTES.RELOAD_RIGHTS])(LoginPage);